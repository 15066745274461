// Module
var code = `<template>
  <require from="./header-title-qr.scss"></require>
  <section
    id="headertitleqr"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'"
  >
    <div class="breadcrumbs">
      <ul>
        <li repeat.for="breadcrumb of breadcrumbs" click.delegate="router.navigateToRoute(breadcrumb.route)">
          <a href="javascript:void(0)" class.bind="\$last ? 'active' : ''">
            \${breadcrumb.name}
          </a>
          <!-- Ajout d'un séparateur, uniquement si ce n'est pas le dernier élément -->
          <span if.bind="!\$last"> > </span>
        </li>
      </ul>
    </div>
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <h1>\${title}</h1>
          <p class="subtitle">\${subtitle}</p>
          <div class="flex-grow"></div>
        </div>
        <div class="flex-grow"></div>
      </div>
      <div class="flex-row bottom">
        <ul>
          <li
            repeat.for="menuItem of menuItems"
            class.bind="(currentInstruction.config.route === 'contacts/:page')
              && (currentInstruction.params.page === menuItem.url) ? 'active' : ''"
            click.delegate="router.navigateToRoute('contacts', { page: menuItem.url })"
          >
            <span class="name"> \${menuItem.name} </span>
            <span class="notifs" if.bind="menuItem.notifications > 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;